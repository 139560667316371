import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import {
  addFullvoterValidation,
  addResearchSchema,
  editFullvoterValidation,
  modalFileSchema,
  modalInputSchema,
} from "../../helper/errorSchema";
import {
  checkFormError,
  formatDate,
  fullVoterTabList,
  handleKeyDown,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { Tabs } from "../../Component/Common/Tabs";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import { getStorage } from "../../helper/storage";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";
import {
  addFullVoter,
  deleteFullVoter,
  deleteFullVoterAc,
  getAllBoothApi,
  getFullVoter,
  singleFullVoter,
  updateActiveSortVoter,
  updateEpicFullVoter,
  updateFullVoter,
  uploadExcelVoterApi,
} from "../../services/voterService";
import { getCastProfession } from "../../services/serviceCastPro";
import UserContext from "../../helper/UserContext";
import { FaWhatsapp ,FaRegFilePdf} from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import NotoDevanagari from "./notoDevanagari";
import { BsEye, BsEyeSlash } from "react-icons/bs";
export const FullVoter = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    // ordering: "booth_number",
    ordering: "",
  };

  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const orderObj = {
    sr_no: "",
    assembly_no: "",
    booth_no: "",
    epic_no: "",
    voter_sr_number: "",
    house_no: "",
    // booth_number: "",
    age: "",
    full_name_e: "",
    full_name_h: "",
    first_name_e: "",
    first_name_h: "",
    middle_name_e: "",
    middle_name_h: "",
    last_name_e: "",
    last_name_h: "",
    gender_e: "",
    gender_h: "",
    address_e: "",
    address_h: "",
    booth_address_e: "",
    booth_address_h: "",
    mobile_no: "",
    colour: "",
    profession: "",
    updated_address: "",
    post: "",
    app_name: "",
    party_name: "",
    voter_type: 2,
    is_voted: false,
    is_activist: false,
    boothFilter: "",
    assemblyFilter: "",
  };

  const userLogin = getStorage("logged-in");
  const { user, setUser } = useContext(UserContext);
  const result = userLogin?.user_branches?.join(",");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObj);
  const [openFleet, isOpenFleet] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
 const  [fullView,setFullView] = useState(false)
  const [filter, setFilter] = useState({
    limit: 25,
    status: status,
    assembly_number:
      userLogin?.user_type !== 1
        ? user?.appDetails?.assembly_number[0]
        : user?.appDetails?.assembly_number[0],
    booth_number: "",
    app_name: userLogin?.app_name,
    party_name: userLogin?.party_name,
    // view_booth_numbers: userLogin?.view_booth_permissions?.map((obj) => obj).join(","),
    // update_booth_numbers:userLogin?.update_booth_permissions?.map((obj) => obj).join(","),
    ...obj,
  });
  const [debouncedFilter, setDebouncedFilter] = useState(filter);
  const [filterModal, setFilterModal] = useState({
    limit: 100,
    status: status,
    ...obj,
  });

  const [formValue, setFormValue] = useState(formObj);
  const [initialForm, setInitialForm] = useState(orderObj);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [isLoadding, setIsLoadding] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [OrderID, setOrderID] = useState({
    id: 1,
    qty: "",
  });

  // const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [all, setAll] = useState(false);
  const [epicNo, setEpicNo] = useState("");
  const [castList, setCastList] = useState([]);
  const [profList, setProfList] = useState([]);
  const [assamblyList, setAssamblyList] = useState([]);
  const [boothList, setBoothList] = useState([]);

  const [dataList, setDataList] = useState([]);
  const [inputValue, setInputValue] = useState({
    name: "",
    ac_no: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
    ac_no: "",
    mobileNumber: "",
  });
  const [disableInp, setDisableInp] = useState({
    name: "",
    ac_no: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const colorLIst = [
    { label: "Green", value: 1 },
    { label: "Red", value: 2 },
    { label: "Yellow", value: 3 },
    { label: "Blue", value: 4 },
  ];

  const column = [
    {
      Header: "",
      accessor: "serial",
    },
    {
      Header: "Serial No.",
      accessor: "sr_no",
    },

    {
      Header: "Ac.No.",
      accessor: "assembly_no",
    },

    {
      Header: "Booth No.",
      accessor: "booth_no",
    },
    {
      Header: "V.S.No.",
      accessor: "voter_sr_number",
    },
    {
      Header: "H.No",
      accessor: "house_no",
    },
    // {
    //   Header: "Full Name",
    //   accessor: "full_name_e",
    // },
    // {
    //   Header: "Full Name",
    //   accessor: "full_name_h",
    // },
    {
      Header: "First Name",
      accessor: "first_name_e",
    },
    // {
    //   Header: "First Name",
    //   accessor: "first_name_h",
    // },

    {
      Header: "Relative Name",
      accessor: "last_name_e",
    },
    // {
    //   Header: "Relative Name",
    //   accessor: "last_name_h",
    // },
    {
      Header: "Gender",
      accessor: "gender_e",
    },
    // {
    //   Header: "Gender",
    //   accessor: "gender_h",
    // },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: "Mobile Numner",
      accessor: "mobile_no",
    },
    {
      Header: "Epic No",
      accessor: "epic_num",
    },

    {
      Header: "Colour",
      accessor: "colorCode",
    },
    {
      Header: "Booth Address",
      accessor: "booth_address_e",
    },

    {
      Header: "Voted",
      accessor: "voteds",
    },

    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const rowDataList = (data) => {
    // console.log(userLogin?.user_type == 2 ? data?.filter(item => userLogin?.update_booth_permissions.includes(item?.assembly_number)):"")
    //(userLogin?.user_type == 2 ? data?.filter(item => userLogin?.view_booth_permissions.includes(item?.assembly_number)):userLogin?.user_type == 3 ?data?.filter(item => userLogin?.view_booth_permissions.includes(item?.booth_number)):data)?
    const listArray = data.map((item, i) => ({
      ...item,
      serial: (
        <div className="serial_div">
          <InputField
            className="anvSearch_input"
            type="checkbox"
            checked={selectedItems?.some((selected) => selected.id === item.id)} // Check if item is selected
            onChange={() => handleCheckboxChange(item)} // Toggle selection
          />
          <div
            className={`color_div ${
              item?.colour == 1
                ? "Green"
                : item?.colour == 2
                ? "Red"
                : item?.colour == 3
                ? "Yellow"
                : item?.colour == 4
                ? "Blue"
                : ""
            }`}
          ></div>
        </div>
      ),
      activists: <>{item.is_activist === true ? "Yes" : "No"}</>,
      voteds: <>{item.is_voted === true ? "Yes" : "No"}</>,
      colorCode: (
        <>
          {item?.colour == 1
            ? "Green"
            : item?.colour == 2
            ? "Red"
            : item?.colour == 3
            ? "Yellow"
            : item?.colour == 4
            ? "Blue"
            : ""}
        </>
      ),
      epic_num: (
        <button
          onClick={() => {
            if (userLogin?.user_type == 1) {
              setEpicNo(item.epic_no);
              handleEditAction(item);
            }
          }}
        >
          {item?.epic_no}
        </button>
      ),

      action: (
        <>
          <div className="actionBtn">
            {/* {userLogin?.user_type === 3 ? (
              userLogin?.update_booth_permissions?.filter((val) =>
                val.includes(item?.booth_number)
              ).length > 0 && (
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => handleEditAction(item)}
                />
              )
            ) : userLogin?.user_type === 2 ? (
              userLogin?.update_booth_permissions?.filter((val) =>
                val.includes(item?.assembly_number)
              ).length > 0 && (
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => handleEditAction(item)}
                />
              )
            ): (
              <img
                src={EditIcon}
                alt=""
                onClick={() => handleEditAction(item)}
              />
            )} */}
            <BsEye style={{fontSize:25,cursor:'pointer'}} onClick={() => {
                 setFullView(true);
                handleEditAction(item)
                handleCheckboxChange(item)
                }} />
              {userLogin?.user_type !== 3 && ( <img src={EditIcon} alt="" onClick={() => {
                 setEpicNo(item.epic_no);
                handleEditAction(item)
                handleCheckboxChange(item)
                }} /> )}
            {userLogin?.user_type !== 3 && (
              <img
                src={DeleteIcon}
                alt=""
                onClick={() => hendleDeleteTag(item, true)}
              />
            )}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  // const { data: dataList, isSuccess } = useQuery(
  //   ["getFullVoter", debouncedFilter],
  //   () => getFullVoter(debouncedFilter, filter),
  //   {
  //     enabled: Boolean(debouncedFilter),
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilter(filter); // Update debounced filter after delay
    }, 500); // Delay in milliseconds (adjust as needed)

    return () => {
      clearTimeout(handler); // Cleanup the timeout on each input change
    };
  }, [filter]);

  useEffect(() => {
    getFullVoterList();
  }, [debouncedFilter]);

  const getFullVoterList = async () => {
    try {
      setLoaderForList(true);
      const res = await getFullVoter(filter);
   
      if(res?.status==200){
        setLoaderForList(false);
        setDataList(res);
      }else{
        setLoaderForList(false);
      }
     
    } catch (error) {}
  };

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getCastAndProList();
  }, []);

  useEffect(() => {
    if (filter?.assembly_number) {
      getBoothList(filter?.assembly_number);
    }
  }, [filter?.assembly_number]);

  useEffect(() => {
    if (userLogin?.user_type !== 1) {
      if (user?.appDetails) {
        const useDetails = user?.appDetails?.assembly_number?.map((i) => {
          return { label: i, value: i };
        });
        setFormOrderName((i) => ({ ...i, assemblyFilter: useDetails[0] }));
        setFormOrderValue((i) => ({ ...i, assemblyFilter: useDetails[0] }));
        setFilter({ ...filter, assembly_number: useDetails[0].value });
        setAssamblyList(useDetails);
      }
    } else {
      if (user?.appDetails) {
        const useDetails = user?.appDetails?.assembly_number?.map((i) => {
          return { label: i, value: i };
        });
        setFormOrderName((i) => ({ ...i, assemblyFilter: useDetails[0] }));
        setFormOrderValue((i) => ({ ...i, assemblyFilter: useDetails[0] }));
        setFilter({ ...filter, assembly_number: useDetails[0].value });
        setAssamblyList(useDetails);
      }
    }
  }, [user?.appDetails]);

  const handleCheckboxChange = (item) => {
    setSelectedItems((prev) => {
      const isChecked = prev.some((selected) => selected.id === item.id);
      if (isChecked) {
        // If already selected, remove it
        return prev.filter((selected) => selected.id !== item.id);
      } else {
        // If not selected, add it
        return [...prev, item];
      }
    });
  };

  //   useEffect(()=>{
  // console.log()
  //     setFilter((s) => ({
  //       ...s,
  //       ...obj,
  //       assembly_number:formOrderName?.assemblyFilter,
  //     }))
  //   },[formOrderName?.assemblyFilter])

  //   useEffect(()=>{
  // setFilter({...filter,assembly_number:formOrderName?.assemblyFilter?.map((item)=>item?.value).join(",")})
  //   },[formOrderName?.assemblyFilter])

  const getCastAndProList = async (item) => {
    const res = await getCastProfession();

    setCastList(
      res?.results?.map((item, index) => {
        return { label: item?.caste_english, value: item?.caste_english };
      })
    );
    setProfList(
      res?.results?.map((item, index) => {
        return {
          label: item?.profession_english,
          value: item?.profession_english,
        };
      })
    );
  };

  const getBoothList = async (as_number) => {
    if (as_number) {
      const res = await getAllBoothApi({ assembly_numbers: as_number });
      const details = res?.results?.booth_numbers?.map((item, index) => {
        return { label: item, value: item };
      });
      setBoothList(details);
      setFilter({ ...filter, booth_number: details[0] });
      setFormOrderName((i) => ({ ...i, boothFilter: details[0] }));
      setFormOrderValue((i) => ({ ...i, boothFilter: details[0] }));
    }
  };

  const clearAll = () => {
    setFormOrderValue(orderObj);
    setFormOrderName(orderObj);
    setInitialForm(orderObj);
    setIsEdit(false);
    setFormValue("");
    setFormError("");
    setFullView(false)
    setEpicNo("")
    setSelectedItems([])
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_activist: !item?.is_activist,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addFullvoterValidation);
      setValidationError(error);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalInputSchema);
      setInputError(error);
    }
  };
  const onSubmitUpload = async () => {
    const validationResult = await checkFormError(
      { excel_file: inputValue.excel_file },
      modalFileSchema
    );

    const formData = new FormData();
    formData.append("excel_file", inputValue?.excel_file);

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleUploadExcel(formData);
      }
    } else {
      setIsLoadding(false);
      setInputError(validationResult);
    }
  };

  const onSubmitDelete = () => {
    deleteAcMutation.mutate({
      ac_no: inputValue?.ac_no,
      app_name: userLogin?.app_name,
      party_name: userLogin?.party_name,
    });
  };

  const handleUploadExcel = (formData) => {
    uploadMutation.mutate(formData);
  };

  const uploadMutation = useMutation(uploadExcelVoterApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.results?.success);
      } else {
        setIsLoadding(false);
        // onErrorResponse(res?.error);
      }
    },
  });

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      formOrderValue.voter_type == 2
        ? addFullvoterValidation
        : editFullvoterValidation
    );

    const payload = Object.keys(orderObj).reduce((acc, key) => {
      acc[key] = formOrderValue[key] || ""; // Map value from formOrderValue or use empty string
      return acc;
    }, {});

    // const payload = {
    //   sr_no: formOrderValue.sr_no,
    //   assembly_no: formOrderValue?.assembly_no,
    //   booth_number: formOrderValue?.booth_number,
    //   epic_number: formOrderValue?.epic_number,
    //   voter_sr_number: formOrderValue?.voter_sr_number,
    //   house_number: formOrderValue?.house_number,
    //   booth_number: formOrderValue?.booth_number,
    //   age: formOrderValue?.age,
    //   full_name_english: formOrderValue?.full_name_english,
    //   full_name_hindi: formOrderValue?.full_name_hindi,
    //   first_name_english: formOrderValue?.first_name_english,
    //   first_name_hindi: formOrderValue?.first_name_hindi,
    //   middle_name_english: formOrderValue?.middle_name_english,
    //   middle_name_hindi: formOrderValue?.middle_name_hindi,
    //   last_name_english: formOrderValue?.last_name_english,
    //   last_name_hindi: formOrderValue?.last_name_hindi,
    //   gender_english: formOrderValue?.gender_english,
    //   gender_hindi: formOrderValue?.gender_hindi,
    //   address_english: formOrderValue?.address_english,
    //   address_hindi: formOrderValue?.address_hindi,
    //   booth_address_english: formOrderValue?.booth_address_english,
    //   booth_address_hindi: formOrderValue?.booth_address_hindi,
    //   mobile_no: formOrderValue?.mobile_no,
    //   colour: formOrderValue?.colour,
    //   profession: formOrderValue?.profession,
    //   updated_address: formOrderValue?.updated_address,
    //   post: formOrderValue?.post,
    //   app_name: formOrderValue?.app_name,
    //   party_name: formOrderValue?.party_name,
    //   voter_type: 2,
    //   is_voted: formOrderValue.is_voted,
    //   is_activist: formOrderValue.is_activist,
    // };

    const payloadUpdate = {};

    Object.keys(formOrderValue).map((item, index) => {
      if (formOrderValue[item] !== initialForm[item]) {
        // formData.append(item, formOrderValue[item]);

        payloadUpdate[item] = formOrderValue[item];
      }
    });

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate(payloadUpdate);
      } else {
        handleAdd(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveSortVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate({
      id: deleteId?.id,
      app_name: deleteId?.app_name,
      party_name: deleteId?.party_name,
    });
  };

  const handleEditAction = (item) => {
    getOrderDetails.mutate({
      id: item?.id,
      app_name: userLogin?.app_name,
      party_name: userLogin?.party_name,
    });
  };
  const getOrderDetails = useMutation(singleFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        const Details = {
          sr_no: res?.results?.sr_no,
          assembly_no: res?.results?.assembly_no,
          booth_no: res?.results?.booth_no,
          epic_no: res?.results?.epic_no,
          voter_sr_number: res?.results?.voter_sr_number,
          house_no: res?.results?.house_no,
          // booth_number: res?.results?.booth_number,
          age: res?.results?.age,
          full_name_e: res?.results?.full_name_e,
          full_name_h: res?.results?.full_name_h,
          first_name_e: res?.results?.first_name_e,
          first_name_h: res?.results?.first_name_h,
          middle_name_e: res?.results?.middle_name_e,
          middle_name_h: res?.results?.middle_name_h,
          last_name_e: res?.results?.last_name_e,
          last_name_h: res?.results?.last_name_h,
          gender_e: res?.results?.gender_e,
          gender_h: res?.results?.gender_h,
          address_e: res?.results?.address_e,
          address_h: res?.results?.address_h,
          booth_address_e: res?.results?.booth_address_e,
          booth_address_h: res?.results?.booth_address_h,
          mobile_no: res?.results?.mobile_no,
          colour: res?.results?.colour,
          profession: res?.results?.profession,
          updated_address: res?.results?.updated_address,
          post: res?.results?.post,
          app_name: res?.results?.app_name,
          party_name: res?.results?.party_name,
          voter_type: res?.results?.voter_type,
          is_voted: res?.results?.is_voted,
          is_activist: res?.results?.is_activist,
        };
        setFormOrderValue(Details);

        setInitialForm(Details);

        const common = (name, id) => {
          if (name === "currency") {
            let details = colorLIst?.find((i) => i.value == id);

            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
          if (name === "post") {
            let details = castList?.find((i) => i.value == id);

            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
          if (name === "profession") {
            let details = profList?.find((i) => i.value == id);

            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
        };

        setFormOrderName({
          ...formOrderName,
          colour: common("currency", res?.results?.colour),
          profession: common("profession", res?.results?.profession),
          post: common("post", res?.results?.post),
        });

        setIsEdit(true);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.epic_number);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    const validationResult = await checkFormError(
      { ...formValue },
      addResearchSchema
    );
    const payload = {
      formdata: data,
      id: updateId,
    };
    const payloadEpc = {
      formdata: data,
      id: epicNo,
    };

    if (epicNo) {
      updateEpicMutation.mutate(payloadEpc);
    } else {
      updateEpicMutation.mutate(payload);
    }
  };

  const addMutation = useMutation(addFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setFilter({
          ...filter,
          status: 1,
        });
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
        
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateEpicMutation = useMutation(updateEpicFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
        setFullView(false)
            setEpicNo("")
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const deleteMutation = useMutation(deleteFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const deleteAcMutation = useMutation(deleteFullVoterAc, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    // queryClient.refetchQueries("getFullVoter");
    getFullVoterList();
    setIsOpen(false);
    setIsUpload(false);
    setIsDelete(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const handlePageClickModal = async (data) => {
    const currentPage = data.selected;
    setFilterModal((s) => ({
      ...s,
      offset: currentPage * filterModal.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);

    setDeleteId(data);
  };

  const hendleAllCancle = (data, flag) => {
    setOpenDel(true);
    setAll(true);
  };

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const handleSubmit = () => {
    if (!mobileNumber) {
      setInputError("Please enter a valid mobile number");
      return;
    }
    // const imageUrl = "https://example.com/path-to-image.jpg";
    const message = selectedItems
      .map(
        (voter) =>{
console.log(voter)
return `
Vote For BJP
Booth No : ${voter.booth_no}     Sr No : ${voter.sr_no}
Name : ${voter.full_name_h}
Relative Name : ${voter.last_name_h}
Gender/Age : ${voter.gender_e}/${voter.age}
Address : ${voter.house_no} - ${voter.address_h || "N/A"}
Polling Booth : ${voter.booth_address_h || "N/A"}

--------------------------------------------`;
    })
    .join("\n");

const footerMessage = `यह आपकी वोटर स्लिप है इसे दिखा कर 20 नवंबर बुधवार को भारतीय जनता पार्टी के टेबल से आप अपनी वोटर स्लिप लेकर आप अपना कीमती वोट डाल सकते है 
हर वोट जरूरी है कृपया वोट जरूर दे 

आपका सुरेश अवस्थी (प्रत्याशी 77-लोकसभा वाराणसी)
भा जा पा को वोट दे`;

    // const fullMessage = `${message}\n\n${footerMessage}\n\nImage: ${imageUrl}`;
    const fullMessage = `${message}\n\n${footerMessage}`;

    const encodedMessage = encodeURIComponent(fullMessage);

    const url = `https://wa.me/${mobileNumber}?text=${encodedMessage}`;
    window.open(url, "_blank");
    setShowModal(false);
    setMobileNumber('')
  };

  /// ----------- add Branch and product ---------

  const handleGeneratePDF = () => {
    const doc = new jsPDF();

    doc.addFileToVFS("NotoSansDevanagari.ttf", NotoDevanagari);
    doc.addFont("NotoSansDevanagari.ttf", "NotoDevanagari", "normal");
    doc.setFont("NotoDevanagari");
    // Add title
    doc.setFontSize(16);
    doc.text("Voter Details", 14, 10);

    // Define table columns
    const columns = [
      { header: "Sr. No.", dataKey: "sr_no" },
      { header: "House No.", dataKey: "house_no" },
      { header: "First Name (Hindi)", dataKey: "first_name_e" },
      { header: "Relative Name (Hindi)", dataKey: "last_name_e" },
      { header: "EPIC No.", dataKey: "epic_no" },
      { header: "Mobile No.", dataKey: "mobile_no" },
    ];

    // Generate table
    doc.autoTable({
      columns: columns,
      body: selectedItems,
      startY: 20, // Adjust table position
      styles: { font: "helvetica", fontSize: 10 },
    });

    // Save PDF
    doc.save("VoterDetails.pdf");
  };

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <div className="Tab_dropList">
          <Tabs
            TabList={fullVoterTabList}
            handleActive={handleActive}
            activeTab={filter.status}
          />
          <div className="flex_assambly_drop">
            <div>
              <Dropdown
                className="dropdownoforder"
                placeholder="Select Assembly Number"
                name="assembly_number"
                options={assamblyList ? assamblyList : []}
                valueText="value"
                labelText="label"
                isClearable={true}
                // isMulti={true}
                selectedOption={
                  formOrderName?.assemblyFilter
                    ? formOrderName?.assemblyFilter
                    : userLogin?.user_type !== 1 && assamblyList[0]
                }
                setSelectedOption={(data) => {
                  // handleOrderChange("driver", data.value);

                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    assembly_number: data?.value,
                  }));
                  // setFilter({...filter,assembly_number:data.value})
                  setFormOrderName({ ...formOrderName, assemblyFilter: data });
                }}
              />
            </div>
            <div>
              <Dropdown
                className="dropdownoforder"
                placeholder="Select Booth Number"
                name="booth_number"
                options={boothList ? boothList : []}
                valueText="value"
                labelText="label"
                isClearable={true}
                // isMulti={true}
                selectedOption={formOrderName?.boothFilter}
                setSelectedOption={(data) => {
                  // handleOrderChange("driver", data.value);

                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    booth_number: data?.value,
                  }));
                  // setFilter({...filter,assembly_number:data.value})
                  setFormOrderName({ ...formOrderName, boothFilter: data });
                }}
              />
            </div>
          </div>
        </div>
        <div className="checkBox_Cnacle">
        <button className="addPlan" onClick={() => {
              if(selectedItems?.length){
                setShowModal(true)
              }else{
                toast.error("Please Select Voter")
              }
              }}><FaWhatsapp style={{fontSize:"25px",cursor:"pointer"}} /></button>
            
               <button className="addPlan" onClick={() => {
              if(selectedItems?.length){
                handleGeneratePDF()
              }else{
                toast.error("Please Select Voter")
              }
              }}> <FaRegFilePdf style={{fontSize:"25px",cursor:"pointer"}} /></button>
           
          {/* <button className="addPlan" onClick={handleGeneratePDF}>
          Generate PDF
            </button> */}
          {selectedValues.length > 0 && (
            <button className="addPlan" onClick={() => hendleAllCancle()}>
              Cancel
            </button>
          )}
          {userLogin?.user_type !== 3 && (
            <button className="addPlan" onClick={() => setIsDelete(true)}>
              Delete By Ac
            </button>
          )}
          {userLogin?.user_type !== 3 && (
            <button className="addPlan" onClick={() => setIsUpload(true)}>
              Upload
            </button>
          )}
          {userLogin?.user_type !== 3 && (
            <button className="addPlan" onClick={() => setIsOpen(true)}>
              Add Voter
            </button>
          )}
        </div>
      </div>

      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>
          {/* <div className="inr_flex_mini ">
            <InputField
              placeholder="Booth Number"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  booth_number: e.target.value,
                }))
              }
            />
          </div> */}
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Epic No."
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  epic_number: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="First Name"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  first_name_e: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Last Name"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  last_name_e: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select Colour"
              className="dropdownoforder orderModal_Input"
              name="colour"
              options={colorLIst ? colorLIst : []}
              valueText="value"
              labelText="label"
              isClearable={true}
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.colourFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, colour: data?.value });
                setFormOrderName({ ...formOrderName, colourFilter: data });
              }}
            />{" "}
          </div>
          {/* <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select Voted"
              className="dropdownoforder orderModal_Input"
              name="voted"
              options={votedLIst ? votedLIst : []}
              valueText="value"
              labelText="label"
              isClearable={true}
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.votedFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, voted: data?.value });
                setFormOrderName({ ...formOrderName, votedFilter: data });
              }}
            />{" "}
          </div> */}
          {/* <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select Cast"
              className="dropdownoforder orderModal_Input"
              name="cast"
              options={castList ? castList : []}
              valueText="value"
              labelText="label"
              isClearable={true}
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.castFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, cast: data?.value });
                setFormOrderName({ ...formOrderName, castFilter: data });
              }}
            />{" "}
          </div> */}
          {/* <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select prof.."
              className="dropdownoforder orderModal_Input"
              name="prof"
              options={profList ? profList : []}
              valueText="value"
              labelText="label"
              isClearable={true}
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.profFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, profession: data?.value });
                setFormOrderName({ ...formOrderName, profFilter: data });
              }}
            />{" "}
          </div> */}
          {/* <div className="inr_flex_mini ">
            <InputField
              placeholder="Mobile Number"
              className="anvSearch_input"
              maxLength={10}
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  mobile_no: e.target.value,
                }))
              }
            />
          </div> */}
        </div>
      </div>

      <div className="">
        <div className="table-responsive">
          <CustomReactTable
            columns={column}
            data={rowDataList(
              dataList?.results?.length ? dataList?.results : []
            )}
            tableLoading={loaderForList}
            type={"fullVoter"}
          />
          {/* } */}
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <CustomPagination
          onPageChange={handlePageClick}
          currentPage={filter?.currentPage}
          pageCount={Math.ceil(dataList?.count / filter?.limit)}
        />
      )}

      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
            clearAll();
            setIsEdit(false);
            setFullView(false)
            setEpicNo("")
          }}
          disabled={fullView}
          isLoadding={isLoadding}
          onSubmit={(e) => onOrderSubmit(e)}
          title={isEdit ? "Edit Voter" : "Add Voter"}
          submitLabel={isEdit ? "Update Voter" : "Create Voter"}
          inputProps={
            <div className="anvOt_gridBx ">
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Sr Number" : "Sr Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Sr Number "
                  name="sr_no"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.sr_no}
                  error={formError.sr_no}
                  value={formOrderValue?.sr_no}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Assembly Number" : "Assembly Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Assembly Number"
                  name="assembly_no"
                  maxLength={4}
                  onKeyDown={handleKeyDown}
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.assembly_no}
                  error={formError.assembly_no}
                  value={formOrderValue?.assembly_no}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Booth Number" : "Booth Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Booth Number"
                  name="booth_no"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.booth_no}
                  error={formError.booth_no}
                  value={formOrderValue?.booth_no}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Epic Number" : "Epic Number"}
                  labelClass="FormLableKaran"
                  placeholder="Epic Number"
                  name="epic_no"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.epic_no}
                  error={formError.epic_no}
                  value={formOrderValue?.epic_no}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Voter Serial Number" : "Voter Serial Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Voter Serial Number"
                  name="voter_sr_number"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.voter_sr_number}
                  error={formError.voter_sr_number}
                  value={formOrderValue?.voter_sr_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "House Number" : "House Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter House Number"
                  name="house_no"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.house_no}
                  error={formError.house_no}
                  value={formOrderValue?.house_no}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "App Name" : "App Name"}
                  labelClass="FormLableKaran"
                  placeholder="Enter App Name"
                  name="app_name"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.app_name}
                  error={formError.app_name}
                  value={formOrderValue?.app_name}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Party Name" : "Party Name"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Party Name"
                  name="party_name"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.party_name}
                  error={formError.party_name}
                  value={formOrderValue?.party_name}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_outerFull">
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Age" : "Age"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Age"
                    name="age"
                    type="text"
                    labelSpan="*"
                    maxLength={2}
                    disabled={formOrderValue?.voter_type == 1||fullView}
                    onKeyDown={handleKeyDown}
                    focus={!!formError?.age}
                    error={formError.age}
                    value={formOrderValue?.age}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Full Name English" : "Full Name English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Full Name English"
                  name="full_name_e"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.full_name_e}
                  error={formError.full_name_e}
                  value={formOrderValue?.full_name_e}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Full Name Hindi" : "Full Name Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Full Name Hindi"
                  name="full_name_h"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.full_name_h}
                  error={formError.full_name_h}
                  value={formOrderValue?.full_name_h}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "First Name English" : "First Name English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter First Name English"
                  name="first_name_e"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.first_name_e}
                  error={formError.first_name_e}
                  value={formOrderValue?.first_name_e}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "First Name Hindi" : "First Name Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter First Name Hindi"
                  name="first_name_h"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.first_name_h}
                  error={formError.first_name_h}
                  value={formOrderValue?.first_name_h}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Middle Name English" : "Middle Name English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter middle Name English"
                  name="middle_name_e"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.middle_name_e}
                  error={formError.middle_name_e}
                  value={formOrderValue?.middle_name_e}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Middle Name Hindi" : "Middle Name Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Middle Name Hindi"
                  name="middle_name_h"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.middle_name_h}
                  error={formError.middle_name_h}
                  value={formOrderValue?.middle_name_h}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Last Name English" : "Last Name English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Last Name English"
                  name="last_name_e"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.last_name_e}
                  error={formError.last_name_e}
                  value={formOrderValue?.last_name_e}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Last Name Hindi" : "Last Name Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Last Name Hindi"
                  name="last_name_h"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.last_name_h}
                  error={formError.last_name_h}
                  value={formOrderValue?.last_name_h}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Gender English" : "Gender English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Gender English"
                  name="gender_e"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.gender_e}
                  error={formError.gender_e}
                  value={formOrderValue?.gender_e}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Gender Hindi" : "Gender Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Gender Hindi"
                  name="gender_h"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1 || fullView}
                  focus={!!formError?.gender_h}
                  error={formError.gender_h}
                  value={formOrderValue?.gender_h}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Address English" : "Address English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Address English"
                  name="address_e"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.address_e}
                  error={formError.address_e}
                  value={formOrderValue?.address_e}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Address Hindi" : "Address Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Address Hindi"
                  name="address_h"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.address_h}
                  error={formError.address_h}
                  value={formOrderValue?.address_h}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={
                    isEdit ? "Both Address English" : "Both Address English"
                  }
                  labelClass="FormLableKaran"
                  placeholder="Enter Address English"
                  name="booth_address_e"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1 ||fullView}
                  focus={!!formError?.booth_address_e}
                  error={formError.booth_address_e}
                  value={formOrderValue?.booth_address_e}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Both Address Hindi" : "Both Address Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Address Hindi"
                  name="booth_address_h"
                  type="text"
                  labelSpan="*"
                  
                  disabled={formOrderValue?.voter_type == 1||fullView}
                  focus={!!formError?.booth_address_h}
                  error={formError.booth_address_h}
                  value={formOrderValue?.booth_address_h}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Mobile Number" : "Mobile Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Mobile Number"
                  name="mobile_no"
                  type="text"
                  labelSpan="*"
                  disabled={fullView}
                  maxLength={10}
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.mobile_no}
                  error={formError.mobile_no}
                  value={formOrderValue?.mobile_no}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <span>
                  <Label className="FormLableKaran">Color</Label>
                </span>
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Color"
                    name="color"
                    options={colorLIst ? colorLIst : []}
                    valueText="value"
                    labelText="label"
                    disabled={fullView}
                    // components={{ Menu: CustomControl }}
                    focus={!!formError?.colour}
                    error={formError?.colour}
                    selectedOption={formOrderName?.colour}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, colour: data });
                      handleOrderChange("colour", data.value);
                    }}
                  />
                </div>
              </div>
              <div className="anvOt_inputField ">
                <span>
                  <Label className="FormLableKaran">Profession</Label>
                </span>
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Profession"
                    name="profession"
                    options={profList ? profList : []}
                    valueText="value"
                    labelText="label"
                    disabled={fullView}
                    // components={{ Menu: CustomControl }}
                    focus={!!formError?.profession}
                    error={formError?.profession}
                    selectedOption={formOrderName?.profession}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, profession: data });
                      handleOrderChange("profession", data.value);
                    }}
                  />
                </div>
              </div>
              <div className="anvOt_inputField ">
                <span>
                  <Label className="FormLableKaran">Post</Label>
                </span>
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Post"
                    name="post"
                    options={castList ? castList : []}
                    valueText="value"
                    labelText="label"
                    disabled={fullView}
                    // components={{ Menu: CustomControl }}
                    focus={!!formError?.post}
                    error={formError?.post}
                    selectedOption={formOrderName?.post}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, post: data });
                      handleOrderChange("post", data.value);
                    }}
                  />
                </div>
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Updated Address" : "Updated Address"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Address"
                  name="updated_address"
                  type="text"
                  disabled={fullView}
                  focus={!!formError?.updated_address}
                  error={formError.updated_address}
                  value={formOrderValue?.updated_address}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                
              </div>

              <div className="anvOt_outerFull">
                <div className="anvOt_inputField checkboxtext">
                  <InputField
                    className="modal_input "
                    labelClass="FormLableKaran"
                    placeholder="Company Registration Number"
                    name="is_activist"
                    type="checkbox"
                    disabled={fullView}
                    focus={!!formError?.is_activist}
                    error={formError.is_activist}
                    value={formOrderValue?.is_activist}
                    checked={formOrderValue.is_activist === true}
                    onChange={({ target: { name, checked } }) => {
                      handleOrderChange(name, checked);
                    }}
                  />
                  <sapn className="checkboxTextbox">Activist</sapn>
                </div>
              </div>
              <div className="anvOt_outerFull">
                <div className="anvOt_inputField checkboxtext">
                  <InputField
                    className="modal_input "
                    labelClass="FormLableKaran"
                    placeholder="Company Registration Number"
                    name="is_voted"
                    type="checkbox"
                    disabled={fullView}
                    focus={!!formError?.is_voted}
                    error={formError.is_voted}
                    value={formOrderValue?.is_voted}
                    checked={formOrderValue.is_voted === true}
                    onChange={({ target: { name, checked } }) => {
                      handleOrderChange(name, checked);
                    }}
                  />
                  <sapn className="checkboxTextbox">Voted</sapn>
                </div>
              </div>
              {(epicNo || fullView) && <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"10px"}}><button className="addPlan" onClick={() => {
              if(selectedItems?.length){
                setShowModal(true)
              }
              }}><FaWhatsapp style={{fontSize:"25px",cursor:"pointer"}} /></button>
            
               <button className="addPlan" onClick={() => {
              if(selectedItems?.length){
                handleGeneratePDF()
              }
              }}> <FaRegFilePdf style={{fontSize:"25px",cursor:"pointer"}} /></button></div>}
              
            </div>
          }
        />
      )}

      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
      <Modal size="md" isOpen={openFleet} backdrop keyboard centered={true}>
        <ModalHeader toggle={() => isOpenFleet(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody> Are you sure you want to delete </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => isOpenFleet(false)}>
            Cancel
          </Button>
          <Button color="primary">Submit</Button>
        </ModalFooter>
      </Modal>

      <InputModal
        isOpen={isUpload}
        headerName={isEdit ? "Upload" : "Upload"}
        toggle={() => setIsUpload(!isUpload)}
        submitLabel={isEdit ? "Upload " : "Upload"}
        onSubmit={(e) => onSubmitUpload(e)}
        disabled={isEdit ? inputValue.excel_file === disableInp.excel_file : ""}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={"File"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Select File"
            name="excel_file"
            type="file"
            focus={!!inputError.excel_file}
            error={inputError.excel_file}
            // value={inputValue.excel_file}
            onChange={({ target: { name, files } }) =>
              handleOnChange(name, files[0])
            }
          />
        }
      />
      <InputModal
        isOpen={isDelete}
        headerName={isEdit ? "Delete By Ac" : "Delete By Ac"}
        toggle={() => setIsDelete(!isDelete)}
        submitLabel={isEdit ? "Delete " : "Delete"}
        onSubmit={(e) => onSubmitDelete(e)}
        disabled={!inputValue?.ac_no}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={"Enter AC No"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Select File"
            name="ac_no"
            maxLength={4}
            onKeyDown={handleKeyDown}
            focus={!!inputError.ac_no}
            error={inputError.ac_no}
            // value={inputValue.excel_file}
            onChange={({ target: { name, value } }) =>
              handleOnChange(name, value)
            }
          />
        }
      />

      <InputModal
        isOpen={showModal}
        headerName={isEdit ? "Whatsapp Massage" : "Whatsapp Massage"}
        toggle={() => setShowModal(!showModal)}
        submitLabel={isEdit ? "Send " : "Send"}
        onSubmit={(e) => handleSubmit(e)}
        disabled={mobileNumber.length != 12}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={"Mobile Number"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Enter Mobile Number"
            name="mobileNumber"
            maxLength={12}
            onKeyDown={handleKeyDown}
            focus={mobileNumber.length == 10}
            error={inputError?.mobileNumber}
            // value={inputValue.excel_file}
            onChange={({ target: { name, value } }) => {
              let updatedValue = value.replace(/\D/g, '');

            // If the mobile number has exactly 10 digits, prepend +91
            if (updatedValue.length === 10) {
              updatedValue = '91' + updatedValue;
            }
        
            // Update the mobile number state
            setMobileNumber(updatedValue);}
            }
          />
        }
      />
    </>
  );
};


