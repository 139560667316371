import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, FormFeedback } from "reactstrap";
import { setStorage } from "../../helper/storage";
import { InputField } from "../../Component/Common/InputField";
import AuthLayout from "./AuthLayout";
import { loginSchema } from "../../helper/errorSchema";
import { userLogin } from "../../services";
import "react-toastify/dist/ReactToastify.css";
import { checkFormError } from "../../helper";
import logo from "../../../image/get_ai_logo.svg";
import { Loader } from "../../Component/Common/Loader";
import { toast } from "react-toastify";

export function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
  });

  const [inputError, setInputError] = useState(false);

  const submit = async () => {
    const validationResult = await checkFormError(inputValue, loginSchema);
    if (validationResult === true) {
      setIsLoading(true);
      const pyload = {
        ...inputValue,
     
      };
      const res = await userLogin(pyload);
      console.log(res?.results)
      if (res?.success === true) {

        setStorage("logged-in", res?.results);
        navigate("/dashboard");
      } else {
        setInputError((s) => ({
          ...s,
          response: res?.error?.detail,
        }));
        toast.error(res?.error?.detail)
        
      }
      setIsLoading(false);
    } else {
      setInputError(validationResult);
      setIsLoading(false);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, loginSchema);
      setInputError(error);
    }
  };

  const onkeyPress = (e) => {
    if (e.keyCode === 13) {
      submit();
    }
  };

  return (
    <div className="login_div">
      <AuthLayout title={logo}>
        <Form className="login_form">
          <InputField
            className="login_inp"
            placeholder="Enter Username "
            label="Username"
            labelSpan="*"
            labelSpanClass="style_for_star"
            name="username"
            type="text"
            onChange={({ target: { name, value } }) =>
              handleOnChange(name, value)
            }
            focus={!!inputError.username}
            error={inputError.username}
            value={inputValue.username}
            onKeyDown={(e) => onkeyPress(e)}
          />
          <InputField
            className="login_inp"
            placeholder="Enter Your Password "
            label="Password"
            labelSpan="*"
            labelSpanClass="style_for_star"
            name="password"
            type="password"
            onChange={({ target: { name, value } }) =>
              handleOnChange(name, value)
            }
            focus={!!inputError.password}
            error={inputError.password}
            value={inputValue.password}
            onKeyDown={(e) => onkeyPress(e)}
          />

          {inputError.response && (
            <FormFeedback>{inputError.response}</FormFeedback>
          )}
          <div className="d-flex justify-content-end w-100 my-4">
            <Button
              type="button"
              className="w-50 login_form_btn"
              outline
              onClick={() => submit()}
              disabled={isLoading}
            >
              {isLoading ? "Login.." : "Login"}
            </Button>
          </div>
        </Form>
      </AuthLayout>
    </div>
  );
}
