import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useDebounce from "../../helper/customhook";
import { dashboardDataRequest } from "../../services";

import {
  dashboradSecondTabList,
  dashboradTabList,

} from "../../helper";
import { Tabs } from "../../Component/Common/Tabs";
import { InputField } from "../../Component/Common/InputField";
import SwitchField from "../../Component/Common/SwitchField";


export function Dashboard() {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
  };

  const [switchValue, setSwitchValue] = useState(false);
  const [activeTabFleet, setActiveTabFleet] = useState(1);

  const [status, setStatus] = useState(1);

  const [filter, setFilter] = useState({
    dashboard_type: "Admin",
    limit: 20,
    status: status,
    ...obj,
  });

  const [subFilter, setSubFilter] = useState({

    status: status,
  
  });

  const debouncedFilter = useDebounce(filter, 500);
 

  const column = [
    {
      Header: "Fleet Number",
      accessor: "truck_number",
    },
    {
      Header: "Fleet Movement",
      accessor: "spead_mov",
    },
    {
      Header: "Current Location",
      accessor: "address",
    },
    {
      Header: "Ignition",
      accessor: "ignition_en",
    },
    {
      Header: "Driver",
      accessor: "driver_name",
    },
    {
      Header: "Trip Assigned",
      accessor: "trip_number",
    },
  ];
  const columnFleet = [
    {
      Header: "Fleet Number",
      accessor: "truck_number",
    },
    
  ];
  const rowDataList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      name: item?.name,
      designation: item?.designation,
      contact: item?.contact_number,
      spead_mov:item?.speed > 0 ?`${item?.speed}km/hr`:"Stop",
ignition_en:item?.speed > 0 ? "ON":"OFF", 
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));

    return listArray;
  };
  

  const handleActive = (data) => {
    // setLoaderForList(true)
    console.log("main")
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const handleActiveFleet = (data) => {
    setActiveTabFleet(data?.value);
    
    if(data?.value==2){
      setSubFilter({
        status: data.value,
        running:true,
      });
    }
    if(data?.value==3){
      setSubFilter({
        status: data.value,
        stopped: true,
      });
    }
    if(data?.value==1){
      setSubFilter({
        status: data.value,
      });
    
    }

    if(data?.value==4){
      setSubFilter({
        status: data.value,
      });
     
    }
  };
  
  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={dashboradTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
      </div>
      <div className="ques_container">
        <div className="dashboard_container">
          {filter.status === 1 && (
            <div className="dashbordCountBoxFirst">
              
            </div>
          )}
          {filter.status === 2 && (
            <div className="dashboard_iner_container">
              <div className="fleet_moniter_container">
                <div className="fleet_moiter">
                  <InputField
                    placeholder="Search"
                    className="anvSearch_input"
                    value={filter?.search}
                    onChange={(e) =>
                      setFilter((s) => ({
                        ...s,
                        ...obj,
                        search: e.target.value,
                      }))
                    }
                  />
                  <div className="customerStatus">
                    <SwitchField
                      onSwitchChange={(e) => setSwitchValue(e.target.checked)}
                      value={switchValue}
                    />
                    Show Map
                  </div>
                </div>

                <Tabs
                  customClassName="fleet_moniter_dash"
                  TabList={dashboradSecondTabList}
                  handleActive={handleActiveFleet}
                  activeTab={activeTabFleet}
                />
              </div>
              
             
            </div>
          )}
          
          
        </div>
      </div>
    </>
  );
}
