import { performRequest, apiEndPoints, methodType } from "./_apiConfig";
import { commonErrorHandler } from "./voterService";

export const getCastProfession = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.castPro, data, true)
        .then((response) => {

            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};


export const addCastPro = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.castPro, data)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};

export const updateCastPro = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.castPro + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};

export const deleteCastPro = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.castPro + `/${data?.uuid}`
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveCastPro = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.castPro + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};

export const uploadExcelCastProApi = (data = {}) => {
    
    return performRequest(methodType.POST, apiEndPoints.castExcle, data, true, true)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};
