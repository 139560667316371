import config from "./_apiConfig/apiConfig";
import { performRequest, apiEndPoints, methodType } from "./_apiConfig";
import { toast } from "react-toastify";

export const userLogin = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.baseURL + apiEndPoints.userLogin,
    data,
    false
  )
    .then((response) => {
      if (!response.success) {
               
        let errorMessage = "Password incorrect";

        if (response.error?.error) {
           
            errorMessage = typeof response.error.error === "string"
                ? response.error.error
                : response.error.error.join(", "); 
        } else if (Array.isArray(response.error?.ordering)) {
            errorMessage = response.error.ordering.join(", ");
        }

        toast.error(errorMessage);

        // Throw the error for React Query to handle
        throw new Error(errorMessage);
    }
    return response
     
    })
    .catch((error) => {
      return error;
    });
};

export const changeUserPassword = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.baseURL + apiEndPoints.userChangePassword,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const dashboardDataRequest = (data = {}) => {
  return performRequest(
    methodType.GET,
    config.baseURL + apiEndPoints.dashData,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const forgetApi = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.baseURL + apiEndPoints.forget,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const resetPasswordApi = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.baseURL + apiEndPoints.reset,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
