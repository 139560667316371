import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { clearStorage, getStorage } from "../helper/storage";
import Header from "../Component/Layout/Header";
import Sidebar from "../Component/Layout/Sidebar";
import classNames from "classnames";
import { getAllAppDetailsApi } from "../services/voterService";
import UserContext from "../helper/UserContext";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export const ProtectRoutes = ({ children }) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useContext(UserContext);
  const loggedIn = React.useMemo(() => getStorage("logged-in"), []);

  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  useEffect(() => {
    const fetchDetails = async () => {
      if (loggedIn?.access_token) {
        try {
          const res = await getAllAppDetailsApi({
            app_name: loggedIn?.app_name,
            party_name: loggedIn?.party_name,
          });
          if (res?.status === 200) {
            if (JSON.stringify(user.appDetails) !== JSON.stringify(res?.results[0])) {
              setUser({ ...user, appDetails: res?.results[0] });
            }
          }
        } catch (error) {
          console.error("Error fetching app details:", error);
        }
      }
      setIsLoading(false);
    };
    fetchDetails();
  }, [loggedIn]);

  if (isLoading) return <div>Loading...</div>;
  if (!loggedIn?.access_token) {
  return <Navigate to="/login" />;
  }
  return (
    <div className="al_dashboard">
      <div className="d-flex">
        <Sidebar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <div className={classNames("content", { "is-open": sidebarIsOpen })}>
          <Header toggle={toggleSidebar} />
          {loggedIn?.user_type !== 1 ? (
            user?.appDetails?.is_enable ? (
              <div className="_mainContainer">{children}</div>
            ) : (
              user?.appDetails && (
                <div className="_mainContainer">
                  <Modal
                    size="md"
                    isOpen={!user?.appDetails?.is_enable}
                    backdrop
                    keyboard
                    centered={true}
                  >
                    <ModalHeader>App Important Message</ModalHeader>
                    <ModalBody>
                      {user?.appDetails?.error_message || "No App Details"}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={clearStorage}>
                        Logout
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              )
            )
          ) : (
            <div className="_mainContainer">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};
