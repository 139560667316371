import { toast } from "react-toastify";
import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getSortVoter = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.sortVoter, data)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};


export const addSortVoter = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.sortVoter, data,  true, true)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};

export const singleSortVoter = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.sortVoter + `/${data}`, {})
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });

}

export const getAllAppDetailsApi = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.sortVoter, data)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });

}

export const updateSortVoter = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.sortVoter + `/${data?.id}`,
        data?.formdata,true,true
       
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};

export const deleteSortVoter = (data = {}) => {
    
    return performRequest(
        methodType.DELETE,
        apiEndPoints.sortVoter + `/${data}`
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            console.log(error)
            return error;
        });
}

export const updateActiveSortVoter = (data = {}) => {
    const payload = {
        is_enable: data?.is_enable
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.sortVoter + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};


//--------------------


// export const getFullVoter = (data = {}) => {
//     return performRequest(methodType.GET, apiEndPoints.fullVoter, data, true)
//         .then((response) => {

//             return response;
//         })
//         .catch((error) => {
//             throw error;
//         });
// };

export const getFullVoter = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.fullVoter, data, true)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            // Rethrow the error for React Query to handle
            throw error;
        });
};



export const addFullVoter = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.fullVoter, data)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};


export const singleFullVoter = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.fullVoter + `/${data?.id}`, data)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });

}

export const updateFullVoter = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fullVoter + `/${data?.id}`,
        data?.formdata,
        
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};

export const updateEpicFullVoter = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.epicfullVoter + `/${data?.id}`,
        data?.formdata,
        
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};

export const deleteFullVoter = (data = {}) => {
    const { id, app_name, party_name } = data;
    const url = `${apiEndPoints.fullVoter}/${id}?app_name=${encodeURIComponent(app_name)}&party_name=${encodeURIComponent(party_name)}`;
    return performRequest(
        methodType.DELETE,
        url,
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
}

export const deleteFullVoterAc = (data = {}) => {
    console.log(data)
    return performRequest(
        methodType.DELETE,
        apiEndPoints.fullDeleteAc +`?ac_no=${data?.ac_no}&app_name=${data?.app_name}&party_name=${data?.party_name}`,false
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
}



export const updateActiveFullVoter = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fullVoter + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};


export const uploadExcelVoterApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.excelVoter, data, true, true)
        .then((response) => {
            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};


export const getAllBoothApi = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.booth, data,true)
        .then((response) => {

            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};

export const getAllAssbApi = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.assembly, data,true)
        .then((response) => {

            if (!response.success) {
                commonErrorHandler(response); // Handle the error using the common function
              }
              return response
        })
        .catch((error) => {
            return error;
        });
};


export const commonErrorHandler = (response) => {
    let errorMessage = "An unknown error occurred";
  
    if (response.error?.error) {
      errorMessage = typeof response.error.error === "string"
        ? response.error.error
        : response.error.error.join(", ");
    } else if (Array.isArray(response.error?.ordering)) {
      errorMessage = response.error.ordering.join(", ");
    } else if (Array.isArray(response.error?.non_field_errors)) {
        errorMessage = response.error.non_field_errors.join(", ");
      }
  
    // toast.error(errorMessage);
    toast.error(errorMessage ? errorMessage :errorMessage, { position: "bottom-left" });
    
    // Throwing error to let React Query or calling function handle it
    throw new Error(errorMessage);
  };