import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { addBranchApi, getBranch, getTags } from "../../services";
import { toast } from "react-toastify";
import {
  addOrderValidation,
  addResearchSchema,
  modalInputSchema,
  productModalSchema,
  userAddValidation,
} from "../../helper/errorSchema";
import {
  checkFormError,
  dateForXlsx,
  formatDate,
  formatDateList,
  handleKeyDown,
  orderTabList,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  deleteResearch,
  getAllCustomer,
  singleNewDetails,
  updateActiveResearch,
  updateResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import AllocateVehicleModal from "../../Component/Common/AllocateVehicleModal";
import { getDocumentType } from "../../services/documentTypeService";
import { getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import {
  acceptAndRejectStatus,
  addFleetsForOrder,
  addOrder,
  deleteOrder,
  getAllFleetforOrder,
  getAllOrder,
  getdryCargo,
  gettankersCsv,
  singleOrderDetails,
  updateActiveOrder,
  updateOrder,
} from "../../services/orderService";
import { addProductApi, getProduct } from "../../services/productService";
import { getAllDriver } from "../../services/driverService";
import mytruck from "../../../image/tanker.png";
import {
  getAllDrop,
  getAllPeckup,
  getAllPickup,
} from "../../services/locationService";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import { getAllRoute, singleRouteDetails } from "../../services/routeService";
import { components } from "react-select";
import { getUnit } from "../../services/unitService";
import CommonRoute from "../../Component/Common/CommonRoute";
import {
  addExitBorderApi,
  getExitBorder,
} from "../../services/exitBorderService";
import { CommonCustomer } from "../../Component/Common/CommonCustomer";
import { getStorage } from "../../helper/storage";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";
import CheckboxField from "../../Component/Common/CheckboxField";
import { getAllAssbApi, getAllBoothApi } from "../../services/voterService";
import UserContext from "../../helper/UserContext";

const productTypeList = [
  { label: "Liquid", value: 1 },
  { label: "Dry", value: 2 },
];

const userTypeList = [
  { label: "ADMIN", value: 2 },
  { label: "VOLUNTEER", value: 3 },
];

const userTypeSuperList = [
  { label: "SUPER ADMIN", value: 1 },
  { label: "ADMIN", value: 2 },
  { label: "VOLUNTEER", value: 3 },
];

export const Orders = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
  };

  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };
  const userLogin = getStorage("logged-in");

  const orderObj = {
    app_name: "",
    party_name: "",
    username: "",
    mobile_number: "",
    user_type: userLogin?.user_type === 2? userTypeList[1].value:"",
    assembly_number: "",
    password: "",
    password_confirmation: "",
    view_booth_permissions:"",
    update_booth_permissions:"",
    is_active: true,
  };
  const orderObjName = {
    app_name: "",
    party_name: "",
    username: "",
    mobile_number: "",
    user_type: userLogin?.user_type === 2? userTypeList[1]:"",
    assembly_number: "",
    password: "",
    password_confirmation: "",
    view_booth_permissions:"",
    update_booth_permissions:"",
    is_active: true,
  };

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [openFleet, isOpenFleet] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState({
    limit: 17,
    status: status,
    app_name: user?.appDetails?.app_name,
    party_name: user?.appDetails?.party_name,
    customer_id: userLogin?.user_type === 2 ? userLogin?.id : "",
    ...obj,
  });

  const [filterModal, setFilterModal] = useState({
    limit: 100,
    status: status,
    ...obj,
  });

  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [initialForm, setInitialForm] = useState(orderObj);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [preview, setPreview] = useState(null);

  const [isLoadding, setIsLoadding] = useState(false);
  const [boothList, setBoothList] = useState([]);
const [newBoothLIst,setNewBoothLIst] = useState([])
const [assemblyList, setAssemblyList] = useState([]);
const [newAssemblyLis,setNewAssemblyList] = useState([])

  const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [all, setAll] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const togglePasswordVisibility1 = () => {
    setShowPassword1((prev) => !prev);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2((prev) => !prev);
  };
  

  const rolePermiss = [
    {
      module_id: 1,
      module_name: "App",
      permissions: {
        1: false,
        2: false,
        3: false,
        4: false,
      },
    },
    {
      module_id: 2,
      module_name: "User",
      permissions: {
        1: false,
        2: false,
        3: false,
        4: false,
      },
    },

    {
      module_id: 3,
      module_name: "Voter",
      permissions: {
        1: false,
        2: false,
        3: false,
        4: false,
      },
    },
  ];

  const permissionAction = {
    1: { name: "View", label: 1 },
    2: { name: "Create", label: 2 },
    3: { name: "Edit", label: 3 },
    4: { name: "Delete", label: 4 },
  };

  const [inputValue, setInputValue] = useState({
    name: "",
    module_name: "",
    permissionList: [],
    is_dashboard_access: false,
  });

 

  const column = [
    {
      Header: "App Name",
      accessor: "app_name",
    },
    {
      Header: "Assembly Number",
      accessor: "assembly_number",
    },
    {
      Header: "Mobile Numner",
      accessor: "mobile_number",
    },
    {
      Header: "Party Name",
      accessor: "party_name",
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  const rowDataList = (data) => {

    const listArray = data?.map((item, i) => ({
      ...item,
      serial: <></>,
      order_numbers: item?.order_number,
      status: <div className="statusofOrder"></div>,
      route1: "",

      product: "",
      date: formatDateList(item?.added_on),
      qty: `${item?.qty} ${
        item?.product_detail?.product_type === 1 ? "Ltr" : "Ton"
      }`,
      nominated: item?.trip_data?.nominated,
      atDestination: item?.trip_data?.at_destination,
      at_loading: item?.trip_data?.at_loading,
      at_port_entry: item?.trip_data?.at_port_entry,
      cancel: item?.trip_data?.cancel,
      completed: item?.trip_data?.completed,
      delivered: item?.trip_data?.delivered,
      document_ready: item?.trip_data?.document_ready,
      fund_allocation: item?.trip_data?.fund_allocation,
      in_return: item?.trip_data?.in_return,
      in_transit: item?.trip_data?.in_transit,
      action: (
        <>
          <div className="actionBtn" >
            <SwitchField
              onSwitchChange={() => updateTagStatus(item)}
              value={item?.is_active}
              disabled={userLogin?.user_type == 3}
            />
            {userLogin?.user_type!==3 && <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />}
            {userLogin?.user_type!==3 && <img
              src={DeleteIcon}
              alt=""
              onClick={() => hendleDeleteTag(item, true)}
            />}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllOrder", debouncedFilter],
    () => getAllOrder(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );
 

  useEffect(() => {
    if(formOrderValue.assembly_number){
      getBoothList(formOrderValue.assembly_number);
    }
  }, [formOrderValue.assembly_number]);

  useEffect(() => {
    setInputValue((s) => ({
      ...s,
      permissionList: rolePermiss
        ? rolePermiss.map((item) => {
            const permissions = Object.entries(item?.permissions).map((i) => ({
              ...permissionAction[i[0]],
              value: i[1],
            }));
            return {
              ...item,
              permissions,
            };
          })
        : [],
    }));
  }, []);

  useEffect(() => {
    getAssemblyList()
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  const getBoothList = async (item) => {
    const res = await getAllBoothApi({assembly_numbers:formOrderValue.assembly_number});
    
    setBoothList(res?.results?.booth_numbers?.map((item,index)=>{
      return {label:item,value:item}
    }));
  };

  const getAssemblyList = async (item) => {
    const res = await getAllAssbApi();
    
    setAssemblyList(res?.results?.assembly_numbers?.map((item,index)=>{
      return {label:item,value:item}
    }));
  };
  
useEffect(()=>{
setNewBoothLIst([{label:"All",value:"All"},{label:"None",value:"none"},...boothList])
},[boothList])

useEffect(()=>{
  setNewAssemblyList([{label:"All",value:"All"},{label:"None",value:"none"},...assemblyList])
  },[assemblyList])


console.log(formOrderValue)

  const clearAll = () => {
    setFormOrderValue({
      app_name: "",
      party_name: "",
      username: "",
      mobile_number: "",
      user_type: userLogin?.user_type === 2? userTypeList[1].value:"",
      assembly_number: "",
      password: "",
      password_confirmation: "",
      is_active: true,
    });
    setFormOrderName({
      app_name: "",
      party_name: "",
      username: "",
      mobile_number: "",
      user_type: userLogin?.user_type === 2? userTypeList[1]:"",
      assembly_number: "",
      password: "",
      password_confirmation: "",
      is_active: true,
    });
    setInitialForm({
      app_name: "",
      party_name: "",
      username: "",
      mobile_number: "",
      user_type: userLogin?.user_type === 2? userTypeList[1]:"",
      assembly_number: "",
      password: "",
      password_confirmation: "",
      is_active: true,
    });
    setPreview("");
    setIsEdit(false);
    setFormValue("");
    setFormError("");
    setShowPassword1(false)
    setShowPassword2(false)
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.uuid,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...formValue, [name]: value };
    if (name === "tags") stateObj["tags"] = value?.map((i) => i?.value);

    setFormValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, userAddValidation);
      setValidationError(error);
    }
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, userAddValidation);
      setValidationError(error);
    }
  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      userAddValidation
    );

    // const payload = {
    //   app_name: formOrderValue?.app_name,
    //   party_name: formOrderValue?.party_name,
    //   username: formOrderValue?.username,
    //   mobile_number: formOrderValue?.mobile_number,
    //   user_type: formOrderValue?.user_type,
    //   assembly_number: formOrderValue?.assembly_number,
    //   password: formOrderValue?.password,
    //   password_confirmation: formOrderValue?.password_confirmation,
    //   update_booth_permissions:formOrderValue?.update_booth_permissions?.map((i) =>  i?.value),
    //   view_booth_permissions:formOrderValue?.view_booth_permissions?.map((i) =>  i?.value),
    //   is_active: formOrderValue?.is_active,
    // };

    const updatePayload = {};
    Object.keys(formOrderValue).map((item, index) => {
      console.log(initialForm)
      if (formOrderValue[item] !== initialForm[item]) {
        // formData.append(item, formOrderValue[item]);
       
        updatePayload[item] = formOrderValue[item];
        if(item=="update_booth_permissions"){
          updatePayload["update_booth_permissions"] = formOrderValue?.update_booth_permissions?.map((i) =>  i?.value);
        }

        if(item=="view_booth_permissions"){
          updatePayload["view_booth_permissions"] = formOrderValue?.view_booth_permissions?.map((i) =>  i?.value);
        }
      }
    });

    console.log(updatePayload)

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate(updatePayload);
      } else {
        handleAdd(updatePayload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveOrder, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getOrderDetails.mutate(item?.uuid);
  };
  const getOrderDetails = useMutation(singleOrderDetails, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormOrderValue({
          app_name: res?.results?.app_name,
          party_name: res?.results?.party_name,
          username: res?.results.username,
          mobile_number: res?.results.mobile_number,
          assembly_number: res?.results.assembly_number,
          password: res?.results.password,
          password_confirmation: res?.results?.route,
          user_type: res?.results.user_type,
          is_active: res?.results.is_active,
          update_booth_permissions:res?.results?.update_booth_permissions,
          view_booth_permissions:res?.results?.view_booth_permissions,
        });

        setInitialForm({
          app_name: res?.results?.app_name,
          party_name: res?.results?.party_name,
          username: res?.results.username,
          mobile_number: res?.results.mobile_number,
          assembly_number: res?.results.assembly_number,
          password: res?.results.password,
          password_confirmation: res?.results?.route,
          user_type: res?.results.user_type,
          update_booth_permissions:res?.results?.update_booth_permissions,
          view_booth_permissions:res?.results?.view_booth_permissions,
          is_active: res?.results.is_active,
        });

        const common = (name, id) => {
          if (name === "view") {
            let details = userTypeList?.find((i) => i.value === id);
            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }

          if (name === "update") {
            let details = userTypeList?.find((i) => i.value === id);
            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
        };

        

        setFormOrderName({
          ...formOrderName,
          update_booth_permissions: res?.results?.update_booth_permissions?.map((item)=>{
            return {label: item, value: item }
           }),
           view_booth_permissions: res?.results?.view_booth_permissions?.map((item)=>{
            return {label: item, value: item }
           }),
           user_type: common("view",res?.results?.user_type)
          
        });

        setIsEdit(true);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.uuid);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    const validationResult = await checkFormError(
      { ...formValue },
      addResearchSchema
    );
    const payload = {
      formdata: data,
      id: updateId,
    };

    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addOrder, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setFilter({
          ...filter,
          status: 1,
        });
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateOrder, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const deleteMutation = useMutation(deleteOrder, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        // onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllOrder");

    setIsOpen(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const handlePageClickModal = async (data) => {
    const currentPage = data.selected;
    setFilterModal((s) => ({
      ...s,
      offset: currentPage * filterModal.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
   
    setDeleteId(data.uuid);
  };

  const hendleAllCancle = (data, flag) => {
    setOpenDel(true);
    setAll(true);
  };

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  /// ----------- add Branch and product ---------

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={orderTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
        <div className="checkBox_Cnacle">
          {selectedValues.length > 0 && (
            <button className="addPlan" onClick={() => hendleAllCancle()}>
              Cancel Orders
            </button>
          )}

          {userLogin?.user_type !== 3 &&<button className="addPlan" onClick={() => setIsOpen(true)}>
            Add User
          </button>}
        </div>
      </div>

      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="User Name"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  username: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Assembly Number"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  assembly_number: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Mobile Number"
              className="anvSearch_input"
              maxLength={10}
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  mobile_number: e.target.value,
                }))
              }
            />
          </div>
        </div>
      </div>

      <div className="table table_hieght">
        <div className="table-responsive">
          {/* {loaderForList ? <div className="loaderGif"> <img src={mytruck} alt="" /></div> :  */}
          {/* <CustomTable
            column={getTableList()}
            data={rowDataList()}
            tableLoading={loaderForList}
            // tableLoading={tableLoading}
          /> */}
          <CustomReactTable
            columns={column}
            data={rowDataList(dataList?.results ? dataList?.results : [])}
            tableLoading={loaderForList}
          />
          {/* } */}
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <CustomPagination
          onPageChange={handlePageClick}
          currentPage={filter?.currentPage}
          pageCount={Math.ceil(dataList?.count / filter?.limit)}
        />
      )}

      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
            clearAll();
            setPreview("");

            setIsEdit(false);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) => onOrderSubmit(e)}
          title={isEdit ? "Edit User" : "Add User"}
          submitLabel={isEdit ? "Update User" : "Create User"}
          inputProps={
            <div>
              <div className="anvOt_gridBx ">
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "App Name" : "App Name"}
                    labelClass="FormLableKaran"
                    placeholder="Enter App Name "
                    name="app_name"
                    type="text"
                    focus={!!formError?.app_name}
                    error={formError.app_name}
                    value={formOrderValue?.app_name}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Party name" : "Party name"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Party name"
                    name="party_name"
                    type="text"
                    focus={!!formError?.party_name}
                    error={formError.party_name}
                    value={formOrderValue?.party_name}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Username" : "Username"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Username"
                    name="username"
                    type="text"
                    labelSpan="*"
                    focus={!!formError?.username}
                    error={formError.username}
                    value={formOrderValue?.username}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Mobile Number" : "Mobile Number"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Mobile Number"
                    name="mobile_number"
                    type="text"
                    maxLength={10}
                    onKeyDown={handleKeyDown}
                    focus={!!formError?.mobile_number}
                    error={formError.mobile_number}
                    value={formOrderValue?.mobile_number}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <span>
                    <Label className="FormLableKaran">User Type</Label>
                  </span>
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      placeholder="Select Uset Type"
                      name="user_type"
                      options={userLogin?.username!=="Super_Veer"?userTypeList :userTypeSuperList}
                      valueText="value"
                      labelText="label"
                      focus={!!formError?.user_type}
                      error={formError?.user_type}
                      disabled={userLogin?.user_type === 2 }
                      selectedOption={formOrderName?.user_type}
                      setSelectedOption={(data) => {
                        setFormOrderName({ ...formOrderName, user_type: data });
                        handleOrderChange("user_type", data?.value);
                      }}
                    />
                  </div>
                </div>
               <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Assembly Number" : "Assembly Number"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Assembly Number"
                    name="assembly_number"
                    type="text"
                    maxLength={4}
                    onKeyDown={handleKeyDown}
                    focus={!!formError?.assembly_number}
                    error={formError.assembly_number}
                    value={formOrderValue?.assembly_number}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                {/* {formOrderValue?.user_type === 2 &&<div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Loksabha Number" : "Loksabha Number"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Loksabha Number"
                    name="loksabha_number"
                    type="text"
                    maxLength={4}
                    onKeyDown={handleKeyDown}
                    focus={!!formError?.loksabha_number}
                    error={formError.loksabha_number}
                    value={formOrderValue?.loksabha_number}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>} */}
                
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Set Password" : "Set Password"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Password"
                    name="password"
                    type="password"
                    labelSpan="*"
                    data="password"
                    showPassword={showPassword1}
                    showHandle={togglePasswordVisibility1}
                    focus={!!formError?.password}
                    error={formError.password}
                    value={formOrderValue?.password}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Repeat Password" : "Repeat Password"}
                    labelClass="FormLableKaran"
                    placeholder="Repeat Password"
                    name="password_confirmation"
                    type="password"
                    labelSpan="*"
                     data="password"
                    showPassword={showPassword2}
                    showHandle={togglePasswordVisibility2}
                    focus={!!formError?.password_confirmation}
                    error={formError.password_confirmation}
                    value={formOrderValue?.password_confirmation}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>

               
                  <div className="anvOt_inputField ">
                    <span>
                      <Label className="FormLableKaran">{"View Booth Permissions"}</Label>
                    </span>
                    <div>
                      <Dropdown
                        className="dropdownoforder"
                        placeholder={"Select Booth"}
                        name="view_booth_permissions"
                        options={newBoothLIst}

                        valueText="value"
                        labelText="label"
                        isMulti={true}
                        // components={{ Menu: CustomControl }}
                        focus={!!formError?.view_booth_permissions}
                        error={formError?.view_booth_permissions}
                        selectedOption={formOrderName?.view_booth_permissions}
                        // onMenuScrollToBottom={loadMoreOptions}
                        // disabled={formOrderValue?.user_type==3 ? !boothList.length > 0  :( !assemblyList?.length > 0 || !formOrderValue?.user_type)}
                        disabled={!formOrderValue?.user_type || formOrderValue?.user_type!==4 && !formOrderValue?.assembly_number}
                        setSelectedOption={(data) => {
                         
                          setFormOrderName({ ...formOrderName, view_booth_permissions: data });
                          handleOrderChange("view_booth_permissions", data);
                        }}
                      />
                    </div>
                  </div>
               
              
                  <div className="anvOt_inputField ">
                    <span>
                      <Label className="FormLableKaran">{"Update Booth Permissions"}</Label>
                    </span>
                    <div>
                      <Dropdown
                        className="dropdownoforder"
                        placeholder={"Select Booth"}
                        name="update_booth_permissions"
                        options={ newBoothLIst}
                        valueText="value"
                        labelText="label"
                        isMulti={true}
                        // disabled={formOrderValue?.user_type==3 ? !boothList.length > 0  :( !assemblyList?.length > 0 || !formOrderValue?.user_type)}
                        disabled={!formOrderValue?.user_type || formOrderValue?.user_type!==4 && !formOrderValue?.assembly_number}
                        // components={{ Menu: CustomControl }}
                        focus={!!formError?.update_booth_permissions}
                        error={formError?.update_booth_permissions}
                        selectedOption={formOrderName?.update_booth_permissions}
                        // onMenuScrollToBottom={loadMoreOptions}
                        setSelectedOption={(data) => {
                          setFormOrderName({ ...formOrderName, update_booth_permissions: data });
                          handleOrderChange("update_booth_permissions", data);
                        }}
                      />
                    </div>
                  </div>

              </div>
            </div>
          }
        />
      )}

      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
      <Modal size="md" isOpen={openFleet} backdrop keyboard centered={true}>
        <ModalHeader toggle={() => isOpenFleet(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody> Are you sure you want to delete </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => isOpenFleet(false)}>
            Cancel
          </Button>
          <Button color="primary">Submit</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
